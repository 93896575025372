import * as React from 'react'
import { navigate } from '@reach/router'
import { Link } from 'gatsby'
import DefaultLayout, { MenuItem } from './DefaultLayout'
import styled from 'styled-components'
import dims from './common/dims'
import colors from './common/colors'
import { useState } from 'react'
import { useEffect } from 'react'
import queryString from 'query-string'

export type LeftBarItem = {
  title: string,
  url?: string,
  level: number,
  isInternal?: boolean
}

type KnowledgeBaseLayoutProps = {
  currentUrl?: string,
  leftBarItems?: LeftBarItem[],
  leftBar?: boolean
}

type ContainerProps = {
  showHeader?: boolean
}

const Container = styled.div<ContainerProps>`
    box-sizing: border-box;
    height: 100vh;
    padding-top: ${props => props.showHeader !== false ? dims.header.height : "0"};

    display: flex;
    flex-direction: row;

    @media (max-width: ${dims.breakpoints.large}) {
      flex-direction: column;
    }
  `

const LeftBar = styled.div`
    @media (max-width: ${dims.breakpoints.large}) {
      display: none;
    }

    width: ${dims.leftBar.width};
    border-right: 1px solid ${colors.grey};
    overflow-y: auto;
    padding-top: 2em;

    ul {
      margin: 0;
      list-style-type: none;
      padding: 0;

      li {
        border-top: 1px solid ${colors.grey};

        &:hover {
              background-color: ${colors.grey};
         }

        a, span {
          display: block;
          padding: 1em;
        }

        span {
          background-color: ${colors.grey};
        }

        &:last-child {
          border-bottom: 1px solid ${colors.grey};
        }

        &.level-1 {
          padding-left: 1.5em;
        }
      }
    }
  `

const SmallNav = styled.div`
    display: none;
    @media (max-width: ${dims.breakpoints.large}) {
      display: block;

      select {
        width: 100%;
      }
    }
  `

const Content = styled.div`
    flex-grow: 1;
    overflow-y: scroll;
    padding: 2em 1em 0 1em;

    > main {
      max-width: 1000px;
      margin: 0 auto;
    }
  `

const KnowledgeBaseLayout : React.FunctionComponent<KnowledgeBaseLayoutProps> = ({ currentUrl, leftBarItems, leftBar, children }) => {
  const onMenuSelect = (event: any) => {
    navigate(event.target.value)
  }

  const menuItems: MenuItem[] = [
    {
      name: 'Home',
      url: '/'
    },
    {
      name: 'API',
      url: '/en/api'
    },
    {
      name: 'Release Notes',
      url: '/release-notes'
    },
    {
      name: 'Roadmap',
      url: '/roadmap'
    },
  ];

  const leftBarItemElements = (leftBarItems || []).map(item => {
    let inner;
    if (item.url) {
      if (item.isInternal) inner = <a href={item.url}>{item.title}</a>
      else inner = <Link to={item.url}>{item.title }</Link>
    }
    else {
      inner = <span>{ item.title }</span>
    }
    return <li className={`level-${item.level}`} key={item.title}>{ inner }</li>
  })

  const leftBarItemElementsDropdown = (leftBarItems || []).map(item => (
    <option key={item.url} value={item.url}>{ item.title }</option>
  ));

  const [showHeader, setShowHeader] = useState(true);
  const [showLeftBar, setShowLeftBar] = useState(leftBar === undefined ? true : leftBar);

  useEffect(() => {
    if (document.location && document.location.search) {
      const parsed = queryString.parse(document.location.search);
      // @ts-ignore
      setShowHeader(parsed['header'] ? parsed['header'] !== "0" : true);

      if (parsed['leftBar']) {
        setShowLeftBar(parsed['leftBar'] ? parsed['leftBar'] !== "0" : true);
      }
    }
  }, [])

  const finalShowLeftBar = leftBarItemElements.length > 0 && showLeftBar !== false

  return (
    <DefaultLayout menuItems={menuItems} showHeader={showHeader}>
      <Container showHeader={showHeader}>
        { finalShowLeftBar && (
          <>
            <LeftBar><ul>{ leftBarItemElements }</ul></LeftBar>
            <SmallNav><select value={currentUrl} onChange={onMenuSelect}>{ leftBarItemElementsDropdown }</select></SmallNav>
          </>
        )}
        <Content>{ children }</Content>
      </Container>
    </DefaultLayout>
  )
}

export default KnowledgeBaseLayout
