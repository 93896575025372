import * as React from 'react'
import "./GlobalLayout.scss";
import Helmet from 'react-helmet'

const GlobalLayout : React.FunctionComponent = ({children}) => {
  return (
    <>
      <Helmet>
        <link href="https://fonts.googleapis.com/css?family=Poppins:400,600,800&display=swap" rel="stylesheet" />
      </Helmet>
      { children }
    </>
  )
}

export default GlobalLayout
