const dims = {
  header: {
    height: "75px"
  },
  leftBar: {
    width: "280px"
  },
  breakpoints: {
    small: "576px",
    medium: "768px",
    large: "992px",
    extraLarge: "1200px",
  }
}

export default dims;
