const colors = {
  blue: "#368cfc",
  green: "#66e399",
  orange: "#fc5914",
  white: "#ffffff",
  grey: "#f2f2f2",
  greyer: "#a6a6a6",
  black: "#001c1f",
  blackish: "#383838"
};

export default colors;
