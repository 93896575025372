import * as React from 'react'

import { graphql, Link, useStaticQuery } from 'gatsby'
import Helmet from 'react-helmet'

// @ts-ignore
import logo from './logo.svg';
import GlobalLayout from './GlobalLayout'
import styled from 'styled-components'
import dims from './common/dims'
import colors from './common/colors'

export type MenuItem = {
  name: string,
  url: string,
  show?: boolean
}

type DefaultLayoutProps = {
  menuItems?: MenuItem[],
  showHeader?: boolean
}

const Header = styled.div`
  position: fixed;
  width: calc(100vw - 4em);
  padding: 0 2em;
  height: ${dims.header.height};
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${colors.white};

  -webkit-box-shadow: 0 0 4px 0 ${colors.greyer};
  -moz-box-shadow: 0 0 4px 0 ${colors.greyer};
  box-shadow: 0 0 4px 0 ${colors.greyer};

  .logo {
    display: flex;
    align-items: center;
    flex-direction: row;

    img {
      height: 3.5em;
    }

    .siteName {
      margin-left: 1em;

      .title {
        margin: 0;
        font-weight: 600;
        font-size: 1em;
      }

      .bySensus {
        @media (max-width: ${dims.breakpoints.large}) {
          display: none;
        }

        font-size: 0.8em;
        color: ${colors.greyer};
        margin: 0;
      }
    }
  }
  
  .menu {
    text-align: right;
    flex-grow: 1;
    
    @media (max-width: ${dims.breakpoints.medium}) {
      display: none;
    }

    a {
      margin-left: 2em;
    }
  }
  
  .icons {
      @media (max-width: ${dims.breakpoints.large}) {
        display: none;
      }

      padding: 0 2em;
      display: flex;
      flex-direction: row;

      i {
        margin: 0 0.5em;
        font-size: 1.6em;
        color: ${colors.greyer};
      }
    }
`


const DefaultLayout : React.FunctionComponent<DefaultLayoutProps> = ({ menuItems, children, showHeader }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          subtitle
        }
      }
    }
  `)

  return (
    <GlobalLayout>
      <div>
        <Helmet>
          <title>Sensus - { data.site.siteMetadata.title}</title>
          <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
          <link href="https://fonts.googleapis.com/css?family=Inconsolata&display=swap" rel="stylesheet" />
          <meta name="Description" content="Sensus BPM Online Knowledge Base" />
        </Helmet>
        { showHeader !== false && (
          <Header>
            <div className="logo">
              <img alt="Sensus Logo" src={logo} />
              <div className="siteName">
                <h5 className="title">{ data.site.siteMetadata.title}</h5>
                <p className="bySensus">{ data.site.siteMetadata.subtitle }</p>
              </div>
            </div>
            <div className="menu">
              { menuItems && menuItems.filter(item => item.show == null || item.show).map(item => (
                <Link className={item.show ? "fadeIn" : ""} key={item.name} to={item.url}>{ item.name }</Link>
              ))}
            </div>
            <div className="icons">
              <i className="material-icons">search</i>
              <i className="material-icons">account_circle</i>
              <i className="material-icons">language</i>
            </div>
          </Header>
        )}
        { children }
      </div>
    </GlobalLayout>
  )
}

export default DefaultLayout
