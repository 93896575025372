import { useEffect, useState } from 'react'
import queryString from 'query-string'

export const useLanguage = () => {
  const [language, setLanguage] = useState("en");
  useEffect(() => {
    if (document.location && document.location.search) {
      const parsed = queryString.parse(document.location.search);
      // @ts-ignore
      setLanguage(parsed["language"] ? parsed["language"] : "en");
    }
  }, []);
  return language
}
